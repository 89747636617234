import * as React from 'react'

import styled from '@emotion/styled'
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import StickyHeader from '../components/StickyHeader'
import { breakpoints } from '../styles/variables'
import { breakpointMq } from '../styles/mixins'
import CanvaLoader from '../components/CanvaLoader'

const StyledPage = styled(Page)({
  [`${breakpointMq(breakpoints.xxs, breakpoints.sm)}`]: {
    padding: 0
  },
  marginBottom: 0
})

const BookPage = () => (
  <IndexLayout showHeader={false}>
    <StickyHeader />
    <StyledPage>
      <Container>
        <CanvaLoader designId="DAEF200bA30" paddingTop="141.4286%" />
      </Container>
    </StyledPage>
  </IndexLayout>
)

export default BookPage
